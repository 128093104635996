import { forwardRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import AsyncCreatableSelect from 'react-select/async-creatable'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Button, buttonVariants } from '@hmn/coolinarika-web-ui/components/Button'

import { Icon, SearchIcon } from '../../../../Icon'
import { styles } from './Input.style'

const suggestionsEnabled = process.env.NEXT_PUBLIC_SUGGESTIONS_ENABLED?.toLowerCase() === 'true'

const selector = ({ search }) => search.isOpen

const InputStyled = styled.div(props => ({ ...styles(props) }))

const CustomInput = props => {
    const { value, onChange, inputRef, setValue, onFocus } = props

    const handleSubmit = () => {
        setValue({ value, label: value })
    }

    const handleBlur = () => {
        if (value) {
            setValue({ value, label: value })
        }
    }

    return (
        <div className="customInputContainer">
            <input
                ref={inputRef}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                className="customInput"
                onFocus={onFocus}
            />
            <Button
                isIconOnly
                transparent
                variant={buttonVariants.SECONDARY}
                className="searchButton"
                onClick={handleSubmit}>
                <Icon className="searchIcon" icon={SearchIcon} />
            </Button>
        </div>
    )
}

const Input = forwardRef(({ className, value, ...restProps }, ref) => {
    const isSearchOpen = useSelector(selector)

    useEffect(() => {
        if (isSearchOpen) {
            ref.current.focus()
        }
    }, [ref, isSearchOpen])

    return (
        <InputStyled className={className} isSearchOpen={isSearchOpen}>
            <AsyncCreatableSelect
                ref={ref}
                className="searchSelectContainer"
                classNamePrefix="searchSelect"
                {...restProps}
            />
        </InputStyled>
    )
})

CustomInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    inputRef: PropTypes.node,
    setValue: PropTypes.func,
    onFocus: PropTypes.func
}

CustomInput.defaultProps = {
    value: '',
    onChange: () => {},
    inputRef: undefined,
    setValue: () => {},
    onFocus: () => {}
}

const components = {
    DropdownIndicator: () => null,
    Input: CustomInput
}

if (!suggestionsEnabled) {
    Object.assign(components, { LoadingIndicator: () => null })
}

Input.propTypes = {
    ...AsyncCreatableSelect.propTypes
}

Input.defaultProps = {
    ...AsyncCreatableSelect.defaultProps,
    components
}

export default Input
